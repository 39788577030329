<template>
  <figure class="logo-container">
    <template v-if="!shortened">
      <img
        v-if="color === 'black'"
        src="../assets/logo/elha-logo-black.svg"
        alt="ELHA logo"
        :class="sizeClass"
      />
      <img
        v-else-if="color === 'white'"
        src="../assets/logo/elha-logo-white.svg"
        alt="ELHA logo"
        :class="sizeClass"
      />
      <img
        v-else-if="color === 'green-white'"
        src="../assets/logo/elha-logo-green-white.svg"
        alt="ELHA logo"
        :class="sizeClass"
      />
    </template>
    <img
      v-else
      src="@/assets/logo/favicon.svg"
      alt="ELHA shortened logo"
      :class="sizeClass"
      class="shortened"
    />
  </figure>
</template>

<script>
export default {
  name: 'Logo',

  props: {
    color: {
      default: 'black',
      type: String,
    },

    size: {
      default: 'lg',
      type: String,
    },

    shortened: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    sizeClass() {
      switch (this.size) {
        case 'lg':
          return 'logo--lg';

        case 'md':
          return 'logo--md';

        case 'sm':
          return 'logo--sm';

        case 'esm':
          return 'logo--esm';

        default:
          return 'logo--lg';
      }
    },
  },
};
</script>

<style scoped>
figure {
  margin: 0;
}

.logo--esm {
  width: 60px;
  height: 27.22px;
}

.logo--sm {
  width: 75px;
  height: 34px;
}

.logo--md {
  width: 100px;
  height: 45.36px;
}

.logo--lg {
  width: 120px;
  height: 54.44px;
}

.logo--sm.shortened {
  width: 28px;
  height: 34px;
}

.logo--md.shortened {
  width: 33px;
  height: 45.36px;
}

.logo--lg.shortened {
  width: 45px;
  height: 54.44px;
}
</style>
