<template>
  <router-link
    v-if="to"
    :to="to"
    type="button"
    class="item"
    :class="{ 'item--danger': isDanger }"
    :disabled="disabled"
  >
    <slot name="icon"></slot>
    <slot></slot>
  </router-link>

  <button
    v-else
    type="button"
    class="item"
    :class="{ 'item--danger': isDanger }"
    @click="emitClick"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'MenuItem',

  props: {
    isDanger: Boolean,
    disabled: Boolean,
    to: {
      required: false,
    },
  },

  methods: {
    emitClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped>
.item {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.425rem;
  color: var(--font-color-700);
  font-weight: var(--regular);
  transition: background-color 150ms;
}

.item:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.item:not(:disabled):hover {
  background-color: var(--gray-color-100);
}

.item {
  border-radius: 5px;
}

.item--danger {
  color: var(--danger-color-500);
}

.item--danger:not(:disabled):hover {
  color: var(--danger-color-500);
}
</style>
