<template>
  <div class="palette-container" v-click-outside="close" :style="style">
    <div class="color color-main" @click="active = !active">
      <font-awesome-icon :icon="fill" :fill="selectedColor" />
    </div>
    <div :class="active ? 'active selector' : 'selector'">
      <div class="palette">
        <div v-for="color in colors" class="color" :key="color" @click="changeColor(color)">
          <span :style="{ backgroundColor: color }"></span>
        </div>
      </div>
      <div>
        <h4>...mas:</h4>
        <input :value="selectedColor" @change="(e) => changeColor(e.target.value)" type="color" />
      </div>
    </div>
  </div>
</template>

<script>
import { faFill } from '@fortawesome/free-solid-svg-icons';
import vClickOutside from 'v-click-outside';

export default {
  name: 'Palette',
  data() {
    return {
      selectedColor: '',
      active: false,
      fill: faFill,
      colors: [
        '#CFFBE2',
        '#A4D6C9',
        '#15b195',
        '#00BEA2',
        '#2B996B',

        '#F3FAFF',
        '#6995BB',
        '#5290F5',
        '#2A99DA',
        '#0054B4',

        '#FD7670',
        '#F9293B',
        '#EA658F',
        '#C462A9',
        '#8E66B7',

        '#FEF9F0',
        '#FFC2B0',
        '#FFBC83',
        '#FFBD53',
        '#F3C319',

        '#DBDBD9',
        '#B8B8B6',
        '#969694',
        '#575755',
        '#2E2E2E',
      ],
    };
  },

  props: {
    startColor: {
      type: String,
      required: true,
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    changeColor(color) {
      this.active = false;
      this.selectedColor = color;
      this.$emit('changeColor', this.selectedColor);
    },

    close() {
      this.active = false;
    },
  },
  computed: {
    style() {
      return {
        '--selected-color': this.selectedColor,
      };
    },
  },

  mounted() {
    this.selectedColor = this.startColor;
  },
};
</script>

<style lang="scss" scoped>
.palette-container {
  position: relative;
  cursor: pointer;
  margin: 0.5em 0;
}

.selector {
  display: none;
  padding: 25px;
  padding-top: 20px;
  position: absolute;
  top: calc(100% + 1rem);
  right: 50%;
  margin: 0;
  padding: 0.375rem 0.25rem;
  background-color: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 9999;
  padding: 1rem;
}

h4 {
  margin-bottom: .5rem;
}

.selector.active {
  display: block;
}

.palette {
  display: grid;
  grid-template-rows: repeat(4, 1.6rem);
  grid-template-columns: repeat(7, 1.6rem);
  gap: 0.5rem;
  margin-bottom: .5rem;
}

input[type='color'] {
  border: none;
  border-radius: 10px;
  height: 1.8rem;
  width: 1.8rem;
  padding: 0;
  transition: transform 0.25s;
}
input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  height: 1.8rem;
  width: 1.8rem;
  padding: 0;
  transition: transform 0.25s;
}

.color {
  height: 1.6rem;
  width: 1.6rem;
  transition: transform 0.25s;
  span {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
  &-main {
    height: 18px;
    width: 18px;
    svg {
      height: 100%;
      width: 100%;
      color: var(--selected-color);
      margin: auto;
    }
  }
}

.color:hover,
input:hover {
  transform: scale(1.2);
}
</style>
