var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar",class:{ 'sidebar-collapsed': !_vm.navIsOpen }},[_c('div',[(_vm.navIsOpen)?_c('logo',{attrs:{"size":"sm"}}):_vm._e(),_c('unicon',{attrs:{"name":"bars","width":"22px"},on:{"click":function($event){_vm.navIsOpen = !_vm.navIsOpen}}})],1),_c('span',{staticClass:"separator"}),_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.path,staticClass:"sidebar__tab"},[(!tab.children)?_c('div',{on:{"click":function($event){_vm.selectedParentTab = {
          path: tab.path,
          isActive: _vm.selectedParentTab.path !== tab.path ? false : !_vm.selectedParentTab.isActive,
        }}}},[_c('router-link',{staticClass:"sidebar__link",attrs:{"to":("/" + (tab.path)),"custom":"","exact-active-class":"sidebar__link--active","active-class":"sidebar__link--active"}},[_c('div',[_c('span',[_c('unicon',{attrs:{"name":tab.icon,"height":"20","width":"20","fill":"currentColor"}})],1),_c('span',[_vm._v(_vm._s(tab.text))])])])],1):_c('div',{staticClass:"sidebar__link",class:{
        'sidebar__link--active': _vm.navIsOpen
          ? _vm.$route.path.includes(tab.path) ||
            (_vm.selectedParentTab.isActive && _vm.selectedParentTab.path === tab.path)
          : _vm.$route.path.includes(tab.path),
      },on:{"click":function($event){_vm.selectedParentTab = {
          path: tab.path,
          isActive: _vm.selectedParentTab.path !== tab.path ? true : !_vm.selectedParentTab.isActive,
        }}}},[_c('div',[_c('span',[_c('unicon',{attrs:{"name":tab.icon,"height":"20","width":"20","fill":"currentColor"}})],1),_c('span',[_vm._v(_vm._s(tab.text))])]),_c('span',{staticClass:"link__arrow"},[_c('unicon',{attrs:{"name":"angle-down","height":"18","width":"18","fill":"currentColor"}})],1)]),_c('div',{staticClass:"link__children"},[(tab.children)?_c('span',[_c('span',_vm._l((tab.children),function(child){return _c('router-link',{key:child.path,staticClass:"sidebar__link link__child",attrs:{"to":("/" + (child.path)),"exact-active-class":"sidebar__link--active","active-class":tab.text !== 'Inicio' ? 'sidebar__link--active' : ''}},[_c('span',[_vm._v(_vm._s(child.text))])])}),1)]):_vm._e()])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }