<template>
  <div v-if="isLoading" class="loading-screen-container">
    <LoadingSpinner />
  </div>
  <div v-else class="container">
    <Sidebar />
    <div class="left">
      <Topbar />
      <router-view class="view"></router-view>
    </div>
    <alert></alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Alert from '../components/Alert.vue';
import LoadingSpinner from '../components/loading/LoadingSpinner.vue';
import Sidebar from '../components/Sidebar.vue';
import Topbar from '../components/Topbar.vue';

export default {
  components: {
    Alert,
    LoadingSpinner,
    Sidebar,
    Topbar,
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  .left {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    min-width: 0;
  }
}

.view {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.5rem 3rem;
  overflow-y: auto;
}

.loading-screen-container {
  display: grid;
  place-items: center;
}
</style>
