<template>
  <div class="topbar">
    <div class="search-bar">
      <input
        v-click-outside="() => (openSearchBar = false)"
        type="search"
        placeholder="Buscar..."
        v-model="searchInput"
        @click="openSearchBar = filteredViews.length"
      />
      <div class="search-bar__results" v-if="openSearchBar && filteredViews.length">
        <span
          v-for="view in filteredViews"
          @click="$router.push({ name: views[view].name })"
          :key="view"
        >
          <unicon :name="views[view].icon" height="18" width="18" fill="currentColor"></unicon>
          {{ view }}
        </span>
      </div>
    </div>
    <div class="right">
      <div class="palettes">
        <palette
          :startColor="company.colors ? company.colors[0] : '#1A96FC'"
          @changeColor="(color) => updateColors(color, 0)"
        />
        <palette
          :startColor="company.colors ? company.colors[1] : '#B4DAFB'"
          @changeColor="(color) => updateColors(color, 1)"
        />
      </div>
      <div class="user__info">
        <span class="initials">{{ initials }}</span>
        <div>
          <span>{{ userProfile.name || 'Usuario' }}</span>
          <span>{{ company.name || 'Empresa' }}</span>
        </div>

        <Menu direction="left" :isGray="true" :closeOnItemClick="false">
          <template #label>
            <unicon
              class="ellipsis"
              name="ellipsis-v"
              fill="currentColor"
              height="16.5px"
              width="16.5px"
            />
          </template>
          <template #options>
            <!-- <menu-item @click="$refs.picker1.open = !$refs.picker1.open">
              <div class="colors">
                <ColorPicker
                  ref="picker1"
                  :value="company.colors[0]"
                  @change="updateColors($event, 0)"
                />
              </div>
              Elegir color 1
            </menu-item>
            <menu-item @click="$refs.picker2.open = !$refs.picker2.open">
              <div class="colors">
                <ColorPicker
                  ref="picker2"
                  :value="company.colors[1]"
                  @change="updateColors($event, 1)"
                />
              </div>
              Elegir color 2
            </menu-item> -->
            <!-- <span class="menu__divider"></span> -->
            <menu-item
              @click="
                logout();
                $router.push({ name: 'Login' });
              "
              :isDanger="true"
            >
              <unicon name="signout" fill="currentColor" height="16px" width="16px"></unicon>
              Cerrar sesión
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
// import ColorPicker from '@/components/ColorPicker.vue';
import Palette from '@/components/Palette.vue';
import vClickOutside from 'v-click-outside';

export default {
  name: 'Topbar',

  components: {
    Menu,
    MenuItem,
    // ColorPicker,
    Palette,
  },

  data: () => ({
    searchInput: '',
    views: {
      'Personas y puestos / Empleados': {
        icon: 'user-circle',
        topics: [],
        name: 'Employees',
      },
      'Personas y puestos / Historial': {
        icon: 'user-circle',
        topics: [],
        name: 'History',
      },
      'Equidad de género': { icon: 'balance-scale', topics: [], name: 'Gender' },
      'Brecha salarial': { icon: 'money-bill', topics: [], name: 'WageGap' },
      'Valorización de puestos / Puntuación': {
        icon: 'suitcase',
        topics: [],
        name: 'JobEvaluationProcess',
      },
      'Valorización de puestos / Valorización': {
        icon: 'suitcase',
        topics: [],
        name: 'JobEvaluation',
      },
      'Estructura Salarial Interna': {
        icon: 'sitemap',
        topics: [],
        name: 'SalaryStructure',
      },
      'Estructura Salarial Externa': {
        icon: 'globe',
        topics: [],
        name: 'SalaryStructure',
      },
      'Gestión de documentos / Documentos': {
        icon: 'folder-open',
        topics: [],
        name: 'Files',
      },
      'Gestión de documentos / Correos': {
        icon: 'folder-open',
        topics: [],
        name: 'Emails',
      },
      Configuración: { icon: 'setting', topics: [], name: 'Settings' },
    },
    filteredViews: [],
    openSearchBar: false,
  }),

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setAlert']),
    ...mapActions(['updateCompany']),
    async updateColors(color, index) {
      const colors = [...this.company.colors];
      colors[index] = color;
      try {
        await this.updateCompany({ ...this.company, colors });
        this.setAlert({
          state: 'success',
          message: 'Color actualizado',
        });
      } catch (e) {
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error al actualizar el color, por favor inténtelo nuevamente',
        });
      }
    },
  },

  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      company: (state) => ({
        ...state.company,
        colors: state.company.colors,
      }),
    }),
    initials() {
      if (!this.userProfile.name) return '';
      const nameArray = this.userProfile.name.split(' ');
      const initialsArray = nameArray.map((word) => word[0]);
      return `${initialsArray[0].toUpperCase()}${(
        initialsArray[1] || this.userProfile.name[1]
      ).toUpperCase()}`;
    },
  },

  watch: {
    searchInput() {
      const query = this.searchInput;
      this.openSearchBar = !!query;
      this.filteredViews = Object.keys(this.views).filter((view) => {
        const topics = [...this.views[view].topics, view];
        const temp = view.toLowerCase().includes(query.toLowerCase());
        const temp2 = topics.some((topic) => topic.toLowerCase().includes(query.toLowerCase()));
        return temp || temp2;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  background-color: var(--gray-color-100);
  height: 34px;
  padding: calc(1.8rem / 2) 3rem;
  //border-left: solid 1.8px var(--gray-color-300);
  border-bottom: solid 1.8px var(--gray-color-300);
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input {
  height: 20px;
  width: 20rem;
  padding: 1rem;
  border-radius: 5px;
  padding-left: 1rem;
  background: white url(../assets/search.svg) no-repeat calc(100% - 1rem) center;
  &:focus {
    border-color: var(--main-color-500);
    box-shadow: var(--main-color-200) 0px 0px 5px;
  }
}

.user__info {
  display: flex;
  align-items: center;
  .unicon.ellipsis {
    cursor: pointer;
    border-radius: 5px;
    padding: 0.2rem 0;
  }
  & > div:nth-child(2) {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-right: 0.7rem;
    span {
      max-width: 8rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.85rem;
      font-weight: var(--medium);
    }
    span:nth-child(2) {
      font-size: 0.755rem;
      font-weight: var(--regular);
      color: var(--font-color-100);
    }
  }
  .initials {
    margin-right: 0.7rem;
    width: 2.125rem;
    height: 2.125rem;
    display: inline-flex;
    place-items: center;
    place-content: center;
    grid-row: 1 / -1;
    color: var(--main-color-500);
    font-weight: var(--medium);
    background-color: var(--main-color-100);
    border-radius: 50%;
  }
}

.search-bar {
  position: relative;

  &__results {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 100%;
    position: absolute;
    top: calc(100% + 0.5rem);
    z-index: 9999;
    padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    gap: 0.8rem;
    span {
      font-size: 0.825rem;
      display: flex;
      align-items: center;
      gap: 0.6rem;
      padding: 0.5rem 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: var(--gray-color-100);
      }
    }
  }

  .unicon {
    color: var(--font-color-500);
  }
}
.palettes {
  display: flex;
  gap: 1rem;
}
.right {
  display: flex;
  gap: 2rem;
}
</style>
