const TABS = [
  {
    text: 'Inicio',
    icon: 'home',
    path: 'inicio',
    name: 'Home',
  },
  {
    text: 'Personas y puestos',
    icon: 'user-circle',
    path: 'personas-y-puestos',
    requiredRole: 'employees',
    children: [
      {
        text: 'Empleados',
        path: 'personas-y-puestos/empleados',
        name: 'Employees',
      },
      {
        text: 'Historial',
        path: 'personas-y-puestos/historial',
        name: 'History',
      },
    ],
  },
  {
    text: 'Equidad de género',
    icon: 'balance-scale',
    path: 'equidad-de-genero',
    requiredRole: 'gender',
  },
  {
    text: 'Brecha salarial',
    icon: 'money-bill',
    path: 'brecha-salarial',
    name: 'WageGap',
    requiredRole: 'wagegap',
  },
  {
    text: 'Valorización de puestos',
    icon: 'suitcase',
    path: 'valorizacion-de-puestos',
    requiredRole: 'valuation',
    children: [
      {
        text: 'Puntuación',
        path: 'valorizacion-de-puestos/puntuacion',
        name: 'JobEvaluationProcess',
      },
      {
        text: 'Valorización',
        path: 'valorizacion-de-puestos/valorizacion',
        name: 'JobEvaluation',
      },
    ],
  },
  {
    text: 'Estructura Salarial Interna',
    icon: 'sitemap',
    path: 'estructura-salarial-interna',
    requiredRole: 'salary',
  },
  {
    text: 'Estructura Salarial Externa',
    icon: 'globe',
    path: 'estructura-salarial-externa',
    requiredRole: 'pay',
  },
  {
    text: 'Gestión de documentos',
    icon: 'folder-open',
    path: 'gestion-de-documentos',
    name: 'Files',
    requiredRole: 'documents',
    children: [
      {
        text: 'Documentos',
        path: 'gestion-de-documentos/documentos',
      },
      {
        text: 'Correos',
        path: 'gestion-de-documentos/correos',
        name: 'Emails',
      },
    ],
  },
  {
    text: 'Configuración',
    icon: 'setting',
    path: 'ajustes',
    name: 'Settings',
    requiredRole: 'settings',
  },
  {
    text: 'Admin',
    icon: 'setting',
    path: 'admin',
    name: 'Admin',
    requiredRole: 'superadmin',
  },
];

export default TABS;
