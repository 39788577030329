<template>
  <aside class="sidebar" :class="{ 'sidebar-collapsed': !navIsOpen }">
    <div>
      <logo v-if="navIsOpen" size="sm" />
      <!-- <logo v-else size="sm" :shortened="true" /> -->
      <unicon name="bars" width="22px" @click="navIsOpen = !navIsOpen"></unicon>
    </div>

    <span class="separator"></span>
    <div class="sidebar__tab" v-for="tab in tabs" :key="tab.path">
      <div
        v-if="!tab.children"
        @click="
          selectedParentTab = {
            path: tab.path,
            isActive: selectedParentTab.path !== tab.path ? false : !selectedParentTab.isActive,
          }
        "
      >
        <router-link
          :to="`/${tab.path}`"
          custom
          class="sidebar__link"
          exact-active-class="sidebar__link--active"
          active-class="sidebar__link--active"
        >
          <div>
            <span>
              <!-- <font-awesome-icon :icon="icons[tab.icon]" size="lg" /> -->
              <unicon :name="tab.icon" height="20" width="20" fill="currentColor"></unicon>
            </span>
            <span>{{ tab.text }}</span>
          </div>
        </router-link>
      </div>
      <div
        v-else
        class="sidebar__link"
        :class="{
          'sidebar__link--active': navIsOpen
            ? $route.path.includes(tab.path) ||
              (selectedParentTab.isActive && selectedParentTab.path === tab.path)
            : $route.path.includes(tab.path),
        }"
        @click="
          selectedParentTab = {
            path: tab.path,
            isActive: selectedParentTab.path !== tab.path ? true : !selectedParentTab.isActive,
          }
        "
      >
        <div>
          <span>
            <!-- <font-awesome-icon :icon="icons[tab.icon]" size="lg" /> -->
            <unicon :name="tab.icon" height="20" width="20" fill="currentColor"></unicon>
          </span>
          <span>{{ tab.text }}</span>
        </div>
        <span class="link__arrow">
          <!-- <font-awesome-icon :icon="icons.down" size="sm" /> -->
          <unicon name="angle-down" height="18" width="18" fill="currentColor"></unicon>
        </span>
      </div>
      <div class="link__children">
        <span v-if="tab.children">
          <span>
            <router-link
              v-for="child in tab.children"
              :key="child.path"
              :to="`/${child.path}`"
              class="sidebar__link link__child"
              exact-active-class="sidebar__link--active"
              :active-class="tab.text !== 'Inicio' ? 'sidebar__link--active' : ''"
            >
              <span>{{ child.text }}</span>
            </router-link>
          </span>
        </span>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TABS from '@/viewsData';
import Logo from './Logo.vue';

export default {
  name: 'Sidebar',

  components: { Logo },

  data: () => ({
    navIsOpen: true,
    selectedParentTab: { path: '', isActive: false },
    tabs: TABS,
    credential: {},
  }),

  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      company: (state) => state.company,
    }),
  },

  methods: {
    ...mapActions(['logout', 'getUserCredentials']),
  },

  async mounted() {
    this.credentials = await this.getUserCredentials();
    this.tabs = this.tabs.filter(({ requiredRole }) => {
      if (requiredRole) {
        if (this.credentials.admin && requiredRole !== 'superadmin') return true;
        return this.credentials[requiredRole];
      }
      return true;
    });
  },
};
</script>

<style lang="scss" scoped>
.sidebar__tab .sidebar__link--settings:hover {
  background-color: transparent;
}

.sidebar__link--settings.sidebar__link--active {
  background-color: transparent;
}

.sidebar .logo-container {
  margin: 0;
}
.sidebar > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0.5rem 0rem 1.2rem 1rem;
  .unicon {
    fill: var(--gray-color-900);
    border-radius: 100%;
    cursor: pointer;
    &:hover {
      fill: var(--main-color-500);
    }
  }
}

.sidebar {
  // flex: 0 0 250px;
  width: 250px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.325rem 1rem;
  background-color: var(--gray-color-100);
  position: relative;
  border-right: solid 1.8px var(--gray-color-300);
  // overflow-y: auto;
  /* font-family: "Museo Sans Rounded"; */
  /* background: linear-gradient(180deg, rgba(21, 177, 149, 1) 50%, rgba(72, 218, 175, 1) 100%); */
}

.separator + .sidebar__tab {
  margin-top: 0.7rem;
}

.sidebar__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.325rem;
  padding: 0.6rem 0.7rem;
  font-size: 0.875rem;
  color: var(--font-color-600);
  font-weight: var(--regular);
  border-radius: 3px;
  // letter-spacing: -0.225px;
  cursor: pointer;

  div,
  a {
    display: flex;
    align-items: center;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    span:first-child {
      min-width: 1.3rem;
      min-height: 1.3rem;
      max-width: 1.3rem;
      max-height: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .unicon {
    color: var(--font-color-400);
  }
}

.sidebar__link--active ~ .link__children {
  display: block;
  .link__child {
    display: flex;
    padding-right: 0;
    justify-self: right;
  }
}

.link__children {
  border-left: solid 2px var(--main-color-500);
  margin-left: 1.3rem;
  border-bottom-left-radius: 10px;
  display: none;
  span {
    // min-width: 100%;
    width: calc(100%);
    display: grid;
  }
  .link__child {
    display: none;
    width: calc(100% - 0.625rem);
    font-size: 0.83rem;
    margin-bottom: 0.325rem;
  }
}

.sidebar__link:hover,
.sidebar__link--active {
  color: var(--main-color-500);
  // background-color: rgba(0, 0, 0, 0.055);
  background-color: white;
  box-shadow: 0.1px 0.1px 7px 0.1px rgba(0, 0, 0, 0.02);
  .unicon {
    color: var(--main-color-500);
  }
}

.link__arrow {
  transform: rotate(90deg);
  margin-left: 0.6rem;
}

.sidebar__link--active {
  .link__arrow {
    transform: rotate(360deg);
  }
}

.sidebar-collapsed {
  // flex: 0 0 62px;
  width: 81.6px;
  min-width: 81.6px;

  .sidebar__link {
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  & > div:first-child {
    margin: 0.85rem auto 1.45rem auto;
    figure {
      margin: auto;
    }
    .unicon {
    }
  }

  .sidebar__link--active ~ .link__children {
    display: none;
  }

  .sidebar__link {
    position: relative;
    &:hover ~ .link__children,
    & ~ .link__children:hover {
      position: absolute;
      left: 0;
      z-index: 2;
      display: flex;
      flex-flow: column;
      border: none;
      & > span {
        background-color: var(--gray-color-100);
        border-radius: 5px;
        margin: 0.5rem 0;
        padding: 0 0.2rem;
        padding-bottom: 0.4rem;
        box-shadow: 0px 0px 5.5px 0px rgba(0, 0, 0, 0.09);
        & > span {
          border-left: solid 2px var(--main-color-500);
          margin: 0.2rem 0.5rem;
          padding: 0 0.5rem;
          padding-right: 1.2rem;
          border-bottom-left-radius: 10px;
        }
      }

      .link__child {
        z-index: 2;
        padding-right: 2.2rem;
        display: flex;
        span {
          display: block;
        }
      }

      .link__child.sidebar__link--active {
        color: var(--main-color-500);
        background-color: white;
      }
    }
  }
}

.sidebar-collapsed .sidebar__link span {
  display: none;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.125rem;
}

.separator {
  border-bottom: solid 1.8px var(--gray-color-300);
  width: 100%;
  position: absolute;
  top: 62.6px;
  left: 0;
}
</style>
