<template>
  <transition name="alert">
    <div v-if="alert.state" class="alert" :class="typeClass">
      <span class="alert__icon">
        <font-awesome-icon :icon="stateIcon" />
      </span>

      <span>{{ alert.message }}</span>

      <button class="alert__close-btn" @click="clearAlert">
        <font-awesome-icon icon="times" />
      </button>
    </div>
  </transition>
</template>

<script>
/* eslint-disable */
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { mapMutations, mapState } from 'vuex';

export default {
  data: () => ({
    errorIcon: faExclamationCircle,
    successIcon: faCheckCircle,
  }),

  watch: {
    'alert.state'(val) {
      if (val) {
        setTimeout(() => this.clearAlert(), 4000);
      }
    },
  },

  computed: {
    ...mapState(['alert']),

    stateIcon() {
      switch (this.alert.state) {
        case 'success':
          return this.successIcon;
        case 'error':
          return this.errorIcon;
        default:
          return this.successIcon;
      }
    },

    typeClass() {
      switch (this.alert.state) {
        case 'success':
          return 'alert--success';

        case 'error':
          return 'alert--danger';

        default:
          return '';
      }
    },
  },

  methods: {
    ...mapMutations(['setAlert']),

    clearAlert() {
      this.setAlert({
        state: '',
        message: '',
      });
    },
  },
};
</script>

<style scoped>
.alert {
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem;
  padding: .8rem;
  display: flex;
  align-items: center;
  grid-gap: 1.125rem;
  gap: 1.125rem;
  font-weight: var(--semi-bold);
  border-radius: 0.375rem;
  box-shadow: 0 0 5px 1px rgba(000, 000, 000, 0.1);
  z-index: 9998;
  font-size: .9rem;
}

.alert--success {
  background-color: var(--success-color-100);
  border: solid 1.5px var(--success-color-500);
  color: var(--success-color-700);
}

.alert--danger {
  background-color: var(--danger-color-100);
  border: solid 1.5px var(--danger-color-500);
  color: var(--danger-color-600);
}

.alert__close-btn {
  color: var(--font-color-300);
}

.alert--danger .alert__icon {
  color: var(--danger-color-500);
}

.alert--success .alert__icon {
  color: var(--success-color-500);
}

.alert-enter,
.alert-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}

.alert-enter-active,
.alert-leave-active {
  transition: transform 300ms, opacity 300ms;
}
</style>
