<template>
  <div
    v-click-outside="close"
    class="custom__menu"
    @click="toggle"
    :class="{ isOpen, disabled, isGray }"
  >
    <slot name="label"></slot>

    <transition name="menu">
      <ul
        v-show="isOpen"
        class="menu__menu"
        :class="`menu__menu--${direction}`"
        @click.stop="isOpen = !closeOnItemClick"
      >
        <div class="menu__beforeOptions" v-if="isOpen && $slots.beforeOptions">
          <slot name="beforeOptions"></slot>
        </div>
        <div class="menu__options" :class="`menu__options--${size}`">
          <slot name="options"></slot>
        </div>
        <div v-if="isOpen && $slots.afterOptions">
          <span class="menu__divider"></span>
          <slot name="afterOptions"></slot>
        </div>
      </ul>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'Menu',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data: () => ({
    isOpen: false,
  }),

  props: {
    direction: {
      default: 'right',
      // below//left//above
    },
    closeOnItemClick: {
      default: true,
    },
    type: {
      default: '',
    },
    size: {
      default: 'large',
    },
    disabled: {
      default: false,
    },
    isGray: {
      default: false,
    },
  },

  methods: {
    open() {
      this.isOpen = !this.disabled && true;
    },

    close() {
      if (this.isOpen) this.$emit('close');
      this.isOpen = false;
    },

    toggle() {
      this.isOpen = !this.disabled && !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
.custom__menu {
  position: relative;
  cursor: pointer;
  transition: color 150ms, background-color 150ms, box-shadow 150ms;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  white-space: nowrap;
  overflow: visible;
  .unicon svg {
    height: 16px;
    width: 16px;
  }
  &:hover,
  &.isOpen {
    color: var(--font-color-700);
    background-color: var(--gray-color-100);
    &.isGray {
      background-color: var(--gray-color-300);
    }
  }
  &.disabled {
    cursor: not-allowed;
  }

  p {
    font-size: 0.81rem;
    font-weight: var(--medium);
    color: inherit;
  }

  .menu__trigger:hover {
    background-color: var(--gray-color-100);
  }

  .menu__trigger:focus {
    background-color: var(--gray-color-100);
  }

  .menu__menu {
    position: absolute;
    top: 0;
    left: calc(100% + 0.2rem);
    width: 240px;
    margin: 0;
    padding: 0.375rem 0.25rem;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(000, 000, 000, 0.1);
    border-radius: 5px;
    z-index: 9999;
    &--below {
      top: calc(100% + 0.4rem);
      left: 0;
    }

    &--left {
      top: calc(100% + 0.4rem);
      right: calc(0);
      left: initial;
    }

    &--above {
      bottom: 0;
      top: initial;
      left: calc(100% + 0.2rem);
    }

    .menu__beforeOptions {
      margin: 0.25rem 0.25rem;
    }

    .menu__options {
      overflow: auto;
      &--large {
        max-height: 20rem;
      }
      &--medium {
        max-height: 13rem;
      }
    }
  }

  .menu-enter,
  .menu-leave-to {
    opacity: 0;
  }

  .menu-enter-active,
  .menu-leave-active {
    transition: opacity 150ms;
  }
}
</style>
